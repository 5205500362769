import { Constructor, Config } from './ts/mobius.d';
import { TrackingContext } from '../../ts/trackingContext';
import * as helper from './lib';
import uuid from '../uuid';

export default class Mobius {
  /**
   * api
   *
   * @param {string} api mobius api.
   */
  public api: string;

  /**
   * config
   *
   * @param {Config} config configuration settings.
   */
  public config: Config;

  /**
   * endpoint
   *
   * @param {string} endpoint mobius api endpoint.
   */
  public endpoint: string;

  /**
   * endpointWithQuery
   *
   * @param {string} endpointWithQuery mobius api endpoint with query string.
   */
  public endpointWithQuery: string;

  /**
   * context
   *
   * @param {unknown} unknown context that will be overwritten in extended class.
   */
  public context;

  /**
   * criteria
   *
   * @param {unknown} unknown criteria that will be overwritten in extended class.
   */
  public criteria;

  /**
   * request
   *
   * @param {unknown} unknown request that will be overwritten in extended class.
   */
  public request;

  /**
   * response
   *
   * @param {unknown} unknown response that will be overwritten in extended class.
   */
  public response;

  /**
   * id
   *
   * @param {string} id unique id for widget.
   */
  public id: string;

  /**
   * token
   *
   * @param {string} token authorization token to access mobius api.
   */
  public token: string;

  /**
   * trackingContext
   *
   * @param {TrackingContext} trackingContext tracking context attached to api calls.
   */
  public trackingContext: TrackingContext;

  /**
   * constructor
   *
   * Method used for creating and initializing the mobius object then
   * instantiate object properties.
   *
   * @param {Request} request request context, criteria, token.
   */
  constructor({
    api,
    config,
    context,
    criteria,
    endpoint,
    token,
    trackingContext,
  }: Constructor) {
    // api
    this.api = helper.getApi(api);

    // token
    this.token = helper.getToken(token);

    // config
    this.config = config;

    // api + endpoint
    this.endpoint = this.api + (endpoint || this.config.endpoint);

    // config & context request
    this.criteria = criteria;

    this.context = context;

    this.request = {
      criteria: {
        ...this.config.criteria,
        ...criteria,
      },
      context: {
        ...this.config.context,
        ...context,
      },
    };

    // tracking context
    this.trackingContext = {
      ...this.config.trackingContext,
      ...trackingContext,
    };
  }

  async updateTrackingContext(): Promise<void> {
    this.trackingContext = await helper.getTrackingContext(
      this.trackingContext,
      0 // setting to 0 to prevent retry - immediate Promise.resolve
    );
  }

  /**
   * initialize
   *
   * The initializer enable asynchronous, promise-based behavior to:
   *
   * 1. pull in the cohesion tracking context
   * 2. build the mobius api endpoint with query string
   * 3. get data from mobius api
   *
   */
  async initialize(): Promise<void> {
    // tracking context
    this.trackingContext = await helper.getTrackingContext(
      this.trackingContext
    );
    // api/endpoint + query params
    this.endpointWithQuery = helper.getUrlQuery(this.endpoint, {
      trackingContext: this.trackingContext,
    });

    let defaultReq;
    if (!this.request) {
      defaultReq = {
        criteria: {
          ...this.config.criteria,
          ...this.criteria,
        },
        context: {
          ...this.config.context,
          ...this.context,
        },
      };
    }

    // mobius response
    const response = await helper.getData<Request>(
      this.endpointWithQuery,
      this.request || defaultReq,
      this.token
    );

    // response data
    try {
      this.response = await response.json();
    } catch {
      this.response = null;
    }

    // id
    this.id = response.headers.get('Request-Id') || uuid();
  }
}

/* eslint-disable no-underscore-dangle */
import { TrackingContext } from '../../../ts/trackingContext';

/**
 * Retrieve cohesion web context and tagular right key on
 * cohesion ready event. This ensures the data has been loaded
 * to dom.
 *
 * @param {Object} defaults tracking context default configuration.
 * @return {Object} trackingContext object
 */
export default (
  defaults: Partial<TrackingContext> = {},
  retryAttemptLimit = 3
): Promise<TrackingContext> => {
  let retryTimeoutId: ReturnType<typeof setTimeout>;

  const RETRY_INTERVAL = 200; // ms
  const TIMEOUT_DURATION = RETRY_INTERVAL * retryAttemptLimit; // 600ms
  // get cookie with key tglr_sess_id
  const SESSION_ID = document.cookie
    .split(';')
    .find((item) => item.includes('tglr_sess_id'))
    ?.split('=')[1];

  // Polls for `_Cohesion` to be ready, then merges the necessary attributes to create a `trackingContext`
  const resolveTrackingContext = (
    retryInterval: number
  ): Promise<TrackingContext> => {
    return new Promise((resolve) => {
      const waitForTrackingContext = () => {
        // Retry in 200ms if the Cohesion library is not accessible
        if (!('cohesion' in window)) {
          retryTimeoutId = setTimeout(waitForTrackingContext, retryInterval);
          // Wait for the webContext to be ready now that the Cohesion library is accessible
        } else {
          window.cohesion('ready', () => {
            // Merge the webContext, writeKey and defaults to form the "trackingContext" (as defined by Mobius)
            resolve({
              webContext: window._Cohesion?.webContext,
              writeKey: window._Cohesion?.tagular?.writeKey,
              ...defaults,
            });
          });
        }
      };
      waitForTrackingContext();
    });
  };

  // Gracefully resolve safe values
  const resolveFallbackValue = (
    timeoutDuration: number
  ): Promise<TrackingContext> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!SESSION_ID) {
          resolve(defaults);
        } else {
          resolve({
            webContext: {
              sessionId: SESSION_ID,
              page: {
                url: window.location.href,
              },
            } as any,
            ...defaults,
          });
        }
      }, timeoutDuration);
    });
  };

  return Promise.race([
    resolveTrackingContext(RETRY_INTERVAL),
    resolveFallbackValue(TIMEOUT_DURATION),
  ]).finally(() => {
    // Regardless of what gets resolved/rejected, don't keep retrying
    clearTimeout(retryTimeoutId);
  });
};

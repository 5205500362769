/**
 * Take in query params and build URL string given a base url.
 * Builds & encodes url were https://example.com/api/ and example
 * result would be:  https://example.com/api/?[$paramKey]=[$paramValue]
 *
 * @param {string} base base URL to add query Params too
 * @param {Object} params query params used to build URL string
 */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (base = null, params = {}) => {
  if (!base) throw Error('base is required');

  // stringify & filter params
  const queryParams = Object.entries(params)
    .map(([key, value]) => {
      // return, if empty or null
      if (value === '' || value === null) {
        return false;
      }

      // stringify objects
      const valueString =
        typeof value === 'object' ? JSON.stringify(value) : value;

      // return key=value
      return `${key}=${encodeURIComponent(valueString)}`;
    })
    .filter((param) => param);

  if (queryParams.length <= 0) {
    return base;
  }

  return base + (base.includes('?') ? '&' : '?') + queryParams.join('&');
};

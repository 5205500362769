import { html, TemplateResult } from 'lit-element';
import { Select } from '../interfaces.d';

export default ({
  exclude,
  handleChange,
  label,
  name,
  options,
  value,
  layout = 'fallback',
  isHidden = false,
  stacked,
}: Select): TemplateResult => {
  const disabled =
    layout !== 'native'
      ? !!options[name].length
      : name === 'subject' && !!options.category.length;
  const defaultSelect = !value || !options[name].length;

  // return, if excluded
  if (exclude && exclude.includes(name)) {
    return null;
  }
  // html
  return html`
    <label for=${name} class="${isHidden && 'hidden'} ${
    stacked ? '' : 'flex-1'
  } ${!disabled ? ' disabled' : ''} qdf-select-section cursor-pointer relative">
      <div class="qdf-label block">${label}</div>
      <select
        id=${name}
        name=${name}
        ?disabled=${!disabled}
        class="${
          stacked ? 'pt-4' : layout === 'simple' ? 'pt-0' : 'top-0 pt-8'
        } block overflow-ellipsis"
        @change=${handleChange}
      >
        <option  value="" ?disabled=${!disabled} .selected=${defaultSelect}>Select a ${name}</option>
        ${
          layout === 'native' && name === 'subject'
            ? options.category.map((cat) => {
                return html`<optgroup label=${cat.name}>
            ${cat.subject.map(
              (sub) =>
                html`<option data-key=${name} .value=${sub.slug} .selected=${
                  value === sub.slug
                }>${sub.name}</option>`
            )}
          </optgroup>`;
              })
            : options[name].map((item) => {
                // exclude general option
                if (item.slug === 'general') {
                  return '';
                }

                // return option
                return html`<option data-key=${name} .value=${
                  item.slug
                } .selected=${value === item.slug}>${item.name}</option>`;
              })
        }
      </select>
    </label>
  `;
};

import isProdEnv from '../isProdEnv';

/*
 * Notice Error
 *
 * @param {string} error message to send to new relic
 */
export default (error: string): void => {
  // return, no new relic or not prod
  if (!window.newrelic || !isProdEnv()) {
    return;
  }

  // notice error
  window.newrelic.noticeError(error);
};

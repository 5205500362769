import isProdEnv from '../isProdEnv';

/*
 * Set Custom Attribute
 *
 * @param {string} attribute new relic attribute to be set
 * @param {string} value string for attribute
 */
export default (attribute: string, value: string | number): void => {
  // return, no new relic or not prod
  if (!window.newrelic || !isProdEnv()) {
    return;
  }

  // set custom attribute
  window.newrelic.setCustomAttribute(attribute, value);
};

import { html, TemplateResult } from 'lit-element';
import { Layout } from '../interfaces.d';
import select from '../components/select';

// Background

export default ({
  button,
  category,
  categoryLabel,
  degree,
  degreeLabel,
  disclosureLabel,
  disclosurePositionRight,
  exclude,
  handleChange,
  handleClick,
  hideDisclosure,
  icon,
  iconReverse,
  image,
  mobileImage,
  options,
  subject,
  subjectLabel,
}: Layout): TemplateResult => {
  return html`
    <style>
      .widget-container {
        ${
          mobileImage
            ? `background: linear-gradient(0deg, rgba(0, 35, 74, 0.5), rgba(0, 35, 74, 0.5)), url(${mobileImage});`
            : image
            ? `background: linear-gradient(0deg, rgba(0, 35, 74, 0.5), rgba(0, 35, 74, 0.5)), url(${image});`
            : 'background: #F5F6F8'
        }

        @media (min-width: 768px) {
          ${
            image
              ? `background: linear-gradient(0deg, rgba(0, 35, 74, 0.5), rgba(0, 35, 74, 0.5)), url(${image});`
              : 'background: #F5F6F8'
          }
        }
        
      }

      .widget-container sonic-tooltip {
        --sonic-tooltip-label-color: ${image ? '#fff' : '#666F78'};
      }
    </style>

    <div class="widget-container flex bg-slate-600 ${
      image ? 'with-image' : ''
    }">
      <h2 class="title"><slot name="title"></slot></h2>
      <h3 class="subtitle"><slot name="subtitle"></slot></h3>
      <div class="form-wrapper">
        <div class="form-container flex" data-test="form-container">

          ${[
            select({
              exclude,
              handleChange,
              label:
                degree?.toLowerCase() === 'bootcamps'
                  ? // eslint-disable-next-line quotes
                    degreeLabel ?? "I'm looking for"
                  : degreeLabel ?? 'I want my',
              name: 'degree',
              options,
              value: degree,
            }),
            select({
              exclude,
              handleChange,
              label: categoryLabel ?? 'In',
              name: 'category',
              options,
              value: category,
            }),
            select({
              exclude,
              handleChange,
              label: subjectLabel ?? 'Focusing on',
              name: 'subject',
              options,
              value: subject,
            }),
          ]}

        </div>

        <div class=${
          disclosurePositionRight ? 'qdf-cta-section' : 'button-container'
        }>

          <button
            type="submit"
            @click=${handleClick}
            class="${
              iconReverse
                ? 'flex flex-row-reverse justify-center items-center'
                : 'block'
            }"
            data-test="qdf-submit-button"
            aria-label="${button}"
          >

            ${
              icon
                ? html`<sonic-icon class="${
                    iconReverse ? 'ml-1' : 'mr-1'
                  }" size="x-small" color="#FFFFFF" icon="${icon}"></sonic-icon>`
                : ''
            }

            ${button}

          </button>
          ${
            hideDisclosure
              ? ''
              : html`
              <sonic-tooltip
              class="block text-xs lg:ml-6 mt-2 text-center"
              label=${
                disclosureLabel
                  ? disclosureLabel
                  : window.HE
                  ? 'Learn More About Our Partners'
                  : 'Advertisement'
              }>
                <p>
                ${window.location.hostname}
                is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
                </p>
              </sonic-tooltip>
              `
          }
        </div>
      </div>
    </div>
  `;
};

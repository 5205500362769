import Mobius from './mobius';
import configDegrees from './config/degrees.config';
import { Constructor } from './ts/mobius.d';
import {
  Request,
  Response,
  Degree,
  Category,
  Subject,
  Search,
} from './ts/degrees.d';

export class Degrees extends Mobius {
  /**
   * request
   *
   * @param {Request} request api request containing context, criteria, & etc.
   */
  public request: Request;

  /**
   * response
   *
   * @param {Response} response response object to return from api.
   */
  public response: Response;

  /**
   * degree
   *
   * Method used to filter for degree(s).
   *
   * @param {string} degrees degree(s) to search for.
   * @return {Degree} filtered degree(s) object;
   */
  degree(degreeSearch: Search = null): Degree[] {
    const results: Degree[] = [];

    // return empty
    if (!this?.response?.degrees) {
      return results;
    }

    // all degrees
    if (!degreeSearch) {
      return this.response.degrees;
    }

    // specific degrees
    const search: Array<string | number> = Array.isArray(degreeSearch)
      ? degreeSearch
      : [degreeSearch];
    this.response.degrees.forEach((degree) => {
      if (search.includes(degree.id) || search.includes(degree.slug)) {
        results.push(degree);
      }
    });

    // return
    return results;
  }

  /**
   * category
   *
   * Method used to filter for category(s), must have valid
   * degree to find corresponding category(s).
   *
   * @param {string} degree degree to search for.
   * @param {string | string[]} categories category(s) to search for.
   * @return {Category} filtered category(s) object;
   */
  category(
    degreeSearch: string | number,
    categorySearch: Search = null
  ): Category[] {
    const results: Category[] = [];

    // return, missing argument
    if (!degreeSearch) {
      return results;
    }

    // degrees
    const degrees = this.degree(degreeSearch);

    // return, no degrees
    if (!degrees.length) {
      return results;
    }

    // all categories
    if (!categorySearch) {
      return degrees[0].categories;
    }

    // specific categories
    const search: Array<string | number> = Array.isArray(categorySearch)
      ? categorySearch
      : [categorySearch];
    degrees[0].categories.forEach((category) => {
      if (search.includes(category.id) || search.includes(category.slug)) {
        results.push(category);
      }
    });

    // return
    return results;
  }

  /**
   * subject
   *
   * Method used to filter for subject(s), must have valid
   * degree and category to find corresponding subject(s).
   *
   * @param {string} degree degree to search for.
   * @param {string} category category to search for.
   * @param {string | string[]} subject subject(s) to search for.
   * @return {Subject} filtered subject(s) object;
   */
  subject(
    degreeSearch: string | number,
    categorySearch: string | number,
    subjectSearch: Search = null
  ): Subject[] {
    const results: Subject[] = [];

    // return, missing arguments
    if (!degreeSearch || !categorySearch) {
      return results;
    }

    // categories
    const categories = this.category(degreeSearch, categorySearch);

    // return, no categories
    if (!categories.length) {
      return results;
    }

    // all subjects
    if (!subjectSearch) {
      return categories[0].subject;
    }

    // specific subjects
    const search: Array<string | number> = Array.isArray(subjectSearch)
      ? subjectSearch
      : [subjectSearch];
    categories[0].subject.forEach((subject) => {
      if (search.includes(subject.id) || search.includes(subject.slug)) {
        results.push(subject);
      }
    });

    // return
    return results;
  }
}

// asynchronous factory function
export default async (props?: Constructor): Promise<Degrees> => {
  const degrees = new Degrees({
    config: configDegrees,
    ...props,
  });
  await degrees.initialize();
  return degrees;
};

import { html, TemplateResult } from 'lit-element';
import { Layout } from '../interfaces.d';
import select from '../components/select';

// Curved, Rounded, RoundedV2

export default ({
  border,
  button,
  category,
  degree,
  exclude,
  handleChange,
  handleClick,
  hideDisclosure,
  options,
  subject,
  icon,
  iconReverse,
  degreeLabel,
  categoryLabel,
  subjectLabel,
  layout,
  disclosureLabel,
}: Layout): TemplateResult => {
  return html`
    ${
      hideDisclosure
        ? ''
        : html`
          <sonic-tooltip
            class="block text-xs text-center ${border ? 'mb-8' : 'mb-4'}"
            label=${
              disclosureLabel
                ? disclosureLabel
                : window.HE
                ? 'Learn More About Our Partners'
                : 'Advertisement'
            }
          >
            <p>
              ${window.location.hostname}
              is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
            </p>
          </sonic-tooltip>
          `
    }

    <slot></slot>

    <div class="widget-container">

      <div class="form-container" data-test="form-container">

        ${[
          select({
            exclude,
            handleChange,
            label:
              degree?.toLowerCase() === 'bootcamps'
                ? // eslint-disable-next-line quotes
                  degreeLabel ?? "I'm looking for"
                : degreeLabel ?? 'I want my',
            name: 'degree',
            options,
            value: degree,
            layout,
          }),
          select({
            exclude,
            handleChange,
            label:
              layout === 'rounded'
                ? 'in the field of...'
                : categoryLabel ?? 'In',
            name: 'category',
            options,
            value: category,
            layout,
          }),
          select({
            exclude,
            handleChange,
            label:
              layout === 'rounded'
                ? 'with a major in...'
                : subjectLabel ?? 'Focusing on',
            name: 'subject',
            options,
            value: subject,
            layout,
          }),
        ]}

        <button
          type="submit"
          @click=${handleClick}
          class="${
            iconReverse
              ? 'flex flex-row-reverse justify-center items-center'
              : 'block'
          }"
          data-test="qdf-submit-button"
          aria-label="${button}"
        >

        ${
          layout === 'curved' || layout === 'rounded'
            ? html`
          <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
          <span class="ml-2 md:hidden">${button}</span>
          `
            : layout === 'rounded-v2'
            ? html`
            <div class="icon-wrapper w-6 h-6 flex items-center justify-center rounded-full">
              <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
                <path fill="#fff" fill-rule="evenodd" d="M5.39961 2.20608c-.84869 0-1.66263.33714-2.26274.93726-.60012.60012-.93726 1.41405-.93726 2.26274 0 .84869.33714 1.66263.93726 2.26274.60011.60012 1.41405.93726 2.26274.93726.84869 0 1.66262-.33714 2.26274-.93726.60012-.60011.93726-1.41405.93726-2.26274 0-.84869-.33714-1.66262-.93726-2.26274s-1.41405-.93726-2.26274-.93726Zm-4.800001 3.2c-.000096-.75544.178114-1.50023.520141-2.17381.34202-.67357.8382-1.25691 1.44818-1.70257.60997-.44566 1.31653-.741048 2.0622-.862152.74566-.121103 1.50939-.064497 2.22905.165214.71967.229708 1.37495.626038 1.91256 1.156758.53761.53071.94237 1.18083 1.18135 1.89747.23901.71664.30541 1.47957.19401 2.22673-.1115.74717-.39774 1.45748-.83549 2.07316l3.85359 3.85362c.1457.1509.2264.3529.2245.5627-.0018.2098-.0859.4104-.2342.5587-.1484.1484-.349.2325-.5588.2343-.2097.0018-.4118-.0788-.5627-.2245L8.18121 9.31888c-.71811.51063-1.56292.81372-2.44186.87612-.87894.0623-1.75808-.1184-2.5411-.52254-.78302-.40411-1.43969-1.01596-1.89806-1.7685C.841818 7.15142.599438 6.28723.599609 5.40608Z" clip-rule="evenodd"/>
              </svg>
            </div>
            <span class="ml-2 text-base text-white">${button}</span>
            `
            : html`
          ${
            icon
              ? html`<sonic-icon class="${
                  iconReverse ? 'ml-1' : 'mr-1'
                }" size="x-small" color="#FFFFFF" icon="${icon}"></sonic-icon>`
              : ''
          }
          ${button}
          `
        }

        </button>

      </div>

    </div>
  `;
};

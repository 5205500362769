import { html, TemplateResult } from 'lit-element';
import { Select } from '../interfaces.d';

export default ({
  exclude,
  handleChange,
  label,
  name,
  options,
  value,
  layout = 'fallback',
  isHidden = false,
  stacked,
}: Select): TemplateResult => {
  const disabled =
    layout !== 'native'
      ? !!options[name].length
      : name === 'subject' && !!options.category.length;
  const defaultSelect = !value || !options[name].length;
  const stepPrefix =
    (name === 'degree' && 'First, ') ||
    (name === 'category' && 'Next, ') ||
    (name === 'subject' && 'Finally, ') ||
    '';
  const itemObject = options[name].find((item) => item.slug === value);
  const itemLabel = itemObject ? itemObject.name : '';

  // return, if excluded
  if (exclude && exclude.includes(name)) {
    return null;
  }
  // html
  return html`
    <label for=${name} class="${
      isHidden ? 'hidden' : ''
    } ${
      stacked ? '' : 'flex-1'
    } ${
      !disabled ? ' disabled' : '' // applies disabled style
    } qdf-select-section cursor-pointer relative">
      <div class="qdf-label block ${
        value ? 'qdf-label-selected' : '' // changes label style when selected
      }">${label} <span>&nbsp;${itemLabel || name}&nbsp;</span></div> 
      <select
        id=${name}
        name=${name}
        class="${
          stacked ? 'pt-4' : layout === 'simple' ? 'pt-0' : 'top-0 pt-8'
        } ${
          !disabled ? 'disabled' : '' // applies disabled style
        } block overflow-ellipsis"
        @change=${handleChange}
      >
        <option value="" class="" .selected=${defaultSelect}>${stepPrefix}select a ${name}</option>
        ${
          layout === 'native' && name === 'subject'
            ? options.category.map((cat) => {
                return html`<optgroup label=${cat.name}>
            ${cat.subject.map(
              (sub) =>
                html`<option data-key=${name} .value=${sub.slug} .selected=${
                  value === sub.slug
                }>&nbsp;${sub.name}&nbsp;</option>`
            )}
          </optgroup>`;
              })
            : options[name].map((item) => {
                // exclude general option
                if (item.slug === 'general') {
                  return '';
                }

                // return option
                return html`<option data-key=${name} .value=${
                  item.slug
                } .selected=${value === item.slug}>&nbsp;${item.name}&nbsp;</option>`;
              })
        }
      </select>
    </label>
  `;
};

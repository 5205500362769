import { html, TemplateResult } from 'lit-element';
import { Layout } from '../interfaces.d';
import select from '../components/select';

export default ({
  border,
  button,
  category,
  degree,
  exclude,
  handleChange,
  handleClick,
  target,
  options,
  subject,
  hideDisclosure,
  hideForm,
  icon,
  degreeLabel,
  categoryLabel,
  subjectLabel,
  iconReverse,
  subLayout,
  stacked,
}: Layout): TemplateResult => {
  return html`
  <slot name="top"></slot>

    <div
      class="widget-container grid-cols-5 ${border ? 'rounded' : ''}"
    >
    <div class="${
      !hideForm && !stacked
        ? 'col-span-5 md:col-span-4'
        : hideForm
        ? 'col-span-3 md:col-span-4'
        : stacked
        ? 'col-span-5'
        : ''
    } flex flex-col md:flex-row justify-between items-center">
      <div class="w-full md:w-auto">
        <slot name="left">
          <ul class="flex justify-between mb-3 md:block md:mb-0 text-[10px] md:text-xs font-semibold leading-5 w-full md:w-40 list-none">
            <li><sonic-icon icon="check" size="small" color="accent-800" type="solid"></sonic-icon>Browse programs</li>
            <li><sonic-icon icon="check" size="small" color="accent-800" type="solid"></sonic-icon>Discover matches</li>
            <li><sonic-icon icon="check" size="small" color="accent-800" type="solid"></sonic-icon>Request info</li>
          </ul>
        </slot>
      </div>

      ${
        !hideForm
          ? html`
      <div class="w-full md:w-auto grow">
        <div class="form-container flex flex-col w-full md:mr-6 md:mb-0 overflow-hidden bg-white ${
          stacked ? 'justify-between' : ''
        }" data-test="form-container">
        ${[
          select({
            exclude,
            handleChange,
            label:
              degree?.toLowerCase() === 'bootcamps'
                ? // eslint-disable-next-line quotes
                  degreeLabel ?? "I'm looking for"
                : degreeLabel ?? 'I want my',
            name: 'degree',
            options,
            value: degree,
            stacked,
          }),
          select({
            exclude,
            handleChange,
            label: categoryLabel ?? 'In',
            name: 'category',
            options,
            value: category,
            layout: subLayout ?? 'native',
            isHidden: subLayout ? false : true,
            stacked,
          }),
          select({
            exclude,
            handleChange,
            label: subjectLabel ?? 'Focusing on',
            name: 'subject',
            options,
            value: subject,
            layout: subLayout ?? 'native',
            stacked,
          }),
        ]}
        </div>
      </div>
      `
          : ''
      }
      </div>

      <div class="${hideForm ? 'col-span-2' : 'col-span-5'} ${
    stacked ? 'md:pt-3 md:col-start-3' : 'md:w-4/5'
  } md:col-span-1 w-full flex flex-col items-center justify-self-end grow-0">
        <button
          type="submit"
          @click=${handleClick}
          class="${
            iconReverse
              ? 'flex flex-row-reverse justify-center items-center'
              : 'block'
          } self-center leading-4 border border-transparent text-xs font-medium rounded-md text-white mb-1"
          data-test="qdf-submit-button"
        >
          ${
            icon
              ? html`<sonic-icon class="${
                  iconReverse ? 'ml-1' : 'mr-1'
                }" size="x-small" color="#FFFFFF" icon="${icon}"></sonic-icon>`
              : ''
          }
          ${button}
        </button>
        ${
          hideDisclosure
            ? ''
            : html`
          <sonic-tooltip
          class="block text-xs mx-auto"
          label=${
            window.HE ? 'Learn More About Our Partners' : 'Advertisement'
          }>
          
          >
          <p>
          ${window.location.hostname}
          is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
          </p>
          </sonic-tooltip>
          `
        }
        <slot name="bottom"></slot>
      </div>
    </div>
  `;
};

import config from '../config/mobius.config';

/**
 * Retrieves token from three different method.
 * 1. pass-through argument
 * 2. window object
 * 3. mobius configuration (only in node env `development`)
 *
 * https://edudirect-admin.highereducation.com/publishers ~ publisher / identity tokens
 *
 * @param {string} token pass through agrument
 * @return {string} tokenId the token to be used on request
 */
export default (token: string = null): string => {
  const params = new URLSearchParams(window.location.search);
  const tokenParam = params.get('mobius-token');
  let tokenId =
    token ||
    window?.HE?.publisher?.identityToken ||
    window?.HE?.mobius?.token ||
    null;

  // token param
  if (tokenParam) {
    return tokenParam;
  }

  // config development environment token
  if (
    !tokenId &&
    (process.env.NODE_ENV === 'development' || process.env.STORYBOOK) &&
    config.token
  ) {
    tokenId = config.token;
  }

  // return token
  return tokenId;
};

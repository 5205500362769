import map from './map.json';

/**
 * Tagular event beam.
 *
 * @param {string} eventName tagular event name.
 * @param {Record<string, unknown>} eventData tagular event data to pass along as payload.
 */
export default (
  eventName: string,
  eventData: Record<string, unknown>,
  log = false
): void => {
  // console log
  if (log) {
    // eslint-disable-next-line no-console
    console.log(eventName, eventData);
  }

  // events
  if ('tagular' in window) {
    // beam
    try {
      window.tagular('beam', eventName, {
        '@type': map[eventName],
        ...eventData,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Unable to fire Tagular beam event.');
    }
  } else {
    // Non-Tagular Event
  }
};

export default {
  endpoint: 'v1/inventory/degrees/query',
  criteria: {},
  context: {
    channel: 'web',
    media: 'organic',
    presentationUrl: window.location.href,
  },
  trackingContext: {},
};

import { TemplateResult } from 'lit-element';
import { Template } from './interfaces.d';
import * as Layouts from './layouts';

export default ({
  border,
  button,
  category,
  categoryLabel,
  degree,
  degreeLabel,
  disclosureLabel,
  disclosurePositionRight,
  exclude,
  handleClick,
  handleChange,
  hideDisclosure,
  tooltipPosition,
  hideForm,
  icon,
  iconReverse,
  image,
  layout,
  mobileImage,
  options,
  stacked,
  subject,
  subjectLabel,
  subLayout,
  target,
}: Template): TemplateResult => {
  switch (layout) {
    case 'fallback': {
      return Layouts.fallback();
    }
    case 'cta': {
      return Layouts.cta({
        border,
        button,
        handleChange,
        handleClick,
        options,
        degree,
        category,
        subject,
        exclude,
        target,
        hideDisclosure,
        tooltipPosition,
        hideForm,
        icon,
        degreeLabel,
        categoryLabel,
        subjectLabel,
        disclosurePositionRight,
        disclosureLabel,
        layout,
      });
    }
    case 'curved':
    case 'rounded':
    case 'rounded-v2': {
      return Layouts.rounded({
        border,
        button,
        handleChange,
        handleClick,
        options,
        degree,
        category,
        subject,
        exclude,
        target,
        hideDisclosure,
        tooltipPosition,
        hideForm,
        icon,
        degreeLabel,
        categoryLabel,
        subjectLabel,
        disclosurePositionRight,
        disclosureLabel,
        layout,
      });
    }
    case 'native': {
      return Layouts.nativeRanking({
        border,
        button,
        handleChange,
        handleClick,
        options,
        degree,
        category,
        subject,
        exclude,
        target,
        hideDisclosure,
        tooltipPosition,
        hideForm,
        icon,
        iconReverse,
        degreeLabel,
        categoryLabel,
        subjectLabel,
        disclosurePositionRight,
        disclosureLabel,
        subLayout,
        stacked,
        layout,
      });
    }
    case 'background': {
      return Layouts.background({
        button,
        category,
        categoryLabel,
        degree,
        degreeLabel,
        disclosureLabel,
        disclosurePositionRight,
        exclude,
        handleChange,
        handleClick,
        hideDisclosure,
        tooltipPosition,
        hideForm,
        icon,
        image,
        mobileImage,
        options,
        subject,
        subjectLabel,
        target,
      });
    }
    case 'horizontalV2':
    case 'horizontalRebrandV2': {
      return Layouts.variation({
        border,
        button,
        handleChange,
        handleClick,
        options,
        degree,
        category,
        subject,
        exclude,
        target,
        hideDisclosure,
        tooltipPosition,
        hideForm,
        icon,
        degreeLabel,
        categoryLabel,
        subjectLabel,
        disclosurePositionRight,
        disclosureLabel,
        layout,
      });
    }
    case 'horizontal':
    case 'vertical':
    case 'horizontalRebrand':
    case 'verticalRebrand':
    case 'simple':
    default: {
      return Layouts.base({
        border,
        button,
        handleChange,
        handleClick,
        options,
        degree,
        category,
        subject,
        exclude,
        target,
        hideDisclosure,
        tooltipPosition,
        hideForm,
        icon,
        degreeLabel,
        categoryLabel,
        subjectLabel,
        disclosurePositionRight,
        disclosureLabel,
        layout,
      });
    }
  }
};

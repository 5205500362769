import config from './mobius/config/mobius.config';

/**
 * isProdEnv
 *
 * Get the production environment
 *
 * @return {boolean} indicating if production env.
 */
const isProdEnv = (): boolean => {
  let prodEnv = true;
  config.stagingEnvs.forEach((env) => {
    if (window.location.hostname.includes(env)) {
      prodEnv = false;
    }
  });

  return prodEnv;
};

export default isProdEnv;

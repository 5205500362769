export default {
  production: 'https://api.mobius.highereducation.com/',
  staging: 'https://api.staging.mobius.highereducation.com/',
  stagingEnvs: ['netlify.app', 'pantheonsite.io', 'localhost', 'lndo.site'],
  token: 'fc6954c9-b2b3-47f1-9749-1c6330009ad8',
  headers: {
    'Content-Type': 'application/json',
    Authorization: null,
    Referer: null,
  },
};

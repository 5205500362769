import { AliasParams } from './tagular.d';

/**
 * getAliasParams
 * gets alias query parameters from tagular
 *
 * @return {AliasParams | undefined}
 */
const getAliasParams = (): AliasParams | undefined => {
  // return, no tagular or getAliasSet
  if (!window?.tagular('getAliasSet')) {
    return;
  }

  const { anonymousId, instanceId, sessionId, tenantId, transitId } =
    window.tagular('getAliasSet');

  // return AliasParams
  // eslint-disable-next-line consistent-return
  return {
    ref_anon: anonymousId,
    ref_inst: instanceId,
    ref_sess: sessionId,
    ref_tenant: tenantId,
    transit_id: transitId,
  };
};

export default getAliasParams;

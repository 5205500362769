import { html, TemplateResult } from 'lit-element';
import { Layout } from '../interfaces.d';

export default ({
  border,
  button,
  handleClick,
  hideDisclosure,
  icon,
}: Layout): TemplateResult => {
  return html`
  <slot></slot>
        <button
          type="submit"
          @click=${handleClick}
          class="flex justify-center items-center text-center rounded-none font-medium text-white"
          data-test="qdf-submit-button"
          data-cta="primary"
        >
          ${
            icon
              ? html`<sonic-icon class="mr-1" size="x-small" color="#FFFFFF" icon="${icon}"></sonic-icon>`
              : ''
          }
          ${button}
        </button>

  `;
};

import config from '../config/mobius.config';

/**
 * Get production or staging api based on environment
 * executed in. Staging environments are specified in the
 * mobuis configuration file. The staging environement api is used
 * to prevent analytics and cap logic counting against production.
 *
 * @return {string} String containing api environment
 */
export default (api?: string): string => {
  const domain = window.location.hostname;
  const params = new URLSearchParams(window.location.search);
  const envParam = params.get('mobius-env');
  const apiParam = params.get('mobius-api');
  let prod = true;

  // custom environment api
  if (apiParam) {
    return apiParam;
  }

  // production environment parameter
  if (envParam === 'production') {
    return config.production;
  }

  // staging environment parameter
  if (envParam === 'staging') {
    return config.staging;
  }

  // constructor prop
  if (api) {
    return api;
  }

  // config stage environments
  for (let i = 0; i < config.stagingEnvs.length; i += 1) {
    if (domain.includes(config.stagingEnvs[i])) {
      prod = false;
      break;
    }
  }

  // node environment
  if (process.env.NODE_ENV === 'development') {
    prod = false;
  }

  // return api
  return prod ? config.production : config.staging;
};

/* eslint-disable no-console */
import setCustomAttribute from '../newRelic/setCustomAttribute';

// type(s)
interface ResourceMetric {
  namespace?: string;
  resource: string;
  measures?: {
    label: string;
    log?: boolean;
    newRelic?: boolean;
    data:
      | 'connectEnd'
      | 'connectStart'
      | 'decodedBodySize'
      | 'domainLookupEnd'
      | 'domainLookupStart'
      | 'duration'
      | 'encodedBodySize'
      | 'entryType'
      | 'fetchStart'
      | 'initiatorType'
      | 'name'
      | 'nextHopProtocol'
      | 'redirectEnd'
      | 'redirectStart'
      | 'requestStart'
      | 'responseEnd'
      | 'responseStart'
      | 'secureConnectionStart'
      | 'startTime'
      | 'transferSize'
      | 'workerStart';
  }[];
}

/**
 * Set Resource Metric
 *
 * @param {string} namespace name of the component
 * @param {string} resource name of the resource
 * @param {Record<string, unknown>} measures object containing label, log, newRelic and data
 */
const setResourceMetric = ({
  namespace,
  resource,
  measures,
}: ResourceMetric): void => {
  // return, no mark name
  if (!resource) {
    return;
  }

  const resourceName = namespace
    ? `${namespace} (R)${resource}`
    : `${resource}`;

  // get resource
  const resourceResult: any = performance
    .getEntriesByType('resource')
    .filter((resourceItem) => resourceItem.name.includes(resource))[0];

  // log metric: console
  if (measures) {
    measures.forEach((measure) => {
      // log metric: console
      if (measure.log) {
        console.log(
          resourceName,
          `\`${measure.label}\``,
          resourceResult[measure.data]
        );
      }

      // log metric: new relic
      if (measure.newRelic && typeof window.newrelic === 'object') {
        setCustomAttribute(
          `${resourceName} - ${measure.label}`,
          resourceResult[measure.data]
        );
      }
    });
  }
};

export default setResourceMetric;

import config from '../config/mobius.config';
import noticeError from '../../newRelic/noticeError';

/**
 * Get the response data from mobius api endpoint.
 *
 * @param {string} url mobius api endpoint to pull data.
 * @param {Request} request api request containing context, criteria, & etc.
 * @param {string} token authorization token to access mobius.
 * @return {Promise<Response>} promise response object to return from api.
 */
export default <Request>(
  url: string,
  request: Request,
  token: string
): Promise<Response> =>
  new Promise((resolve) => {
    if (!url) throw Error('Mobius: api is required');
    if (!request) throw Error('Mobius: request is required');
    if (!token) throw Error('Mobius: token is required');

    return fetch(url, {
      method: 'POST',
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        Referer: window.location.href,
      },
      body: JSON.stringify(request),
    })
      .then((response) => {
        // resolve response, for fallback purposes. still throw console error.
        resolve(response);

        // network response not 200
        if (!response.ok) {
          throw new Error('Mobius: network response is not ok.');
        }

        // request id not returned
        if (!response.headers.get('Request-Id')) {
          throw new Error('Mobius: no request id from mobius response.');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Mobius fetch operation error: ', error);
        noticeError(`Mobius Fetch URL: ${url} Error: ${error}`);
      });
  });

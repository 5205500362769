import { html, TemplateResult } from 'lit-element';
import { Layout } from '../interfaces.d';
import select from '../components/selectVariation';

// HorizontalV2, HorizontalRebrandV2
export default ({
  border,
  button,
  category,
  degree,
  exclude,
  handleChange,
  handleClick,
  options,
  subject,
  hideDisclosure,
  icon,
  iconReverse,
  degreeLabel,
  categoryLabel,
  subjectLabel,
  layout,
  disclosurePositionRight,
  disclosureLabel,
}: Layout): TemplateResult => {
  const topAdLayouts = ['horizontalV2'];
  const bottomAdLayouts = ['horizontalRebrandV2'];

  return html`
    ${
      topAdLayouts.includes(layout)
        ? hideDisclosure
          ? ''
          : html`
            <sonic-tooltip
              class="block text-xs text-center ${border ? 'mb-8' : 'mb-4'}"
              label="${
                disclosureLabel
                  ? disclosureLabel
                  : window.HE
                  ? 'Learn More About Our Partners'
                  : 'Advertisement'
              }"
            >
              <p>
              ${window.location.hostname}
              is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
              </p>
            </sonic-tooltip>
            `
        : ''
    }

    <slot></slot>

    <div class="widget-container ${border ? 'rounded' : ''}">

      <div class="form-container" data-test="form-container">

        ${[
          select({
            exclude,
            handleChange,
            label:
              degree?.toLowerCase() === 'bootcamps'
                ? // eslint-disable-next-line quotes
                  degreeLabel ?? "I'm looking for"
                : degreeLabel ?? 'I want my',
            name: 'degree',
            options,
            value: degree,
            layout,
          }),
          select({
            exclude,
            handleChange,
            label: categoryLabel ?? 'In',
            name: 'category',
            options,
            value: category,
            layout,
          }),
          select({
            exclude,
            handleChange,
            label: subjectLabel ?? 'Focusing on',
            name: 'subject',
            options,
            value: subject,
            layout,
          }),
        ]}

      </div>

      <div class=${
        disclosurePositionRight ? 'qdf-cta-section' : 'button-container'
      }>

        <button
          type="submit"
          @click=${handleClick}
          class="${
            iconReverse
              ? 'flex flex-row-reverse justify-center items-center'
              : 'block'
          }"
          data-test="qdf-submit-button"
          aria-label="${button}"
        >

          ${
            icon
              ? html`<sonic-icon class="${
                  iconReverse ? 'ml-1' : 'mr-1'
                }" size="x-small" color="#FFFFFF" icon="${icon}"></sonic-icon>`
              : ''
          }

          ${button}

        </button>

        ${
          bottomAdLayouts.includes(layout)
            ? hideDisclosure
              ? ''
              : html`
            <sonic-tooltip
            class="block text-xs mx-auto"
            label=${
              disclosureLabel
                ? disclosureLabel
                : window.HE
                ? 'Learn More About Our Partners'
                : 'Advertisement'
            }
            >
              <p>
              ${
                window.location.hostname
              } is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
              </p>
            </sonic-tooltip>
            `
            : ''
        }

      </div>

    </div>
  `;
};

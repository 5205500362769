/* eslint-disable no-console */
import setCustomAttribute from '../newRelic/setCustomAttribute';

// type(s)
interface MarkMetric {
  namespace?: string;
  mark: string;
  measures?: {
    log?: boolean;
    newRelic?: boolean;
    startMark: string;
  }[];
  log?: boolean;
  newRelic?: boolean;
}

/**
 * Set Mark Metric (+ measure)
 *
 * @param {string} namespace name of the component
 * @param {string} mark name of the mark
 * @param {Record<string, unknown>} measures object containing log, newRelic and startMark
 * @param {boolean} newRelic value used to send data to NR
 * @param {boolean} log value used to console log
 */
const setMarkMetric = ({
  namespace,
  mark,
  measures,
  newRelic,
  log,
}: MarkMetric): void => {
  // return, no mark name
  if (!mark) {
    return;
  }

  // mark name & set mark
  const markName = namespace ? `${namespace} (M)${mark}` : `${mark}`;

  // set performance mark
  performance.mark(markName);

  // get performance mark
  const markResult: any = performance.getEntriesByName(markName, 'mark');

  // log metric: console
  if (log) {
    markResult.forEach((result) => {
      console.log(`${result.name} - startTime`, result.startTime);
    });
  }

  // log metric: new relic
  if (newRelic) {
    markResult.forEach((result) => {
      setCustomAttribute(`${result.name} - startTime`, result.startTime);
    });
  }

  // set measure
  if (measures) {
    measures.forEach((measure) => {
      // mark name
      const startMark = namespace
        ? `${namespace} (M)${measure.startMark}`
        : `${measure.startMark}`;

      // measure name
      const measureName = `${startMark} -> (M)${mark} - duration`;

      // set measurement
      performance.measure(measureName, startMark, markName);

      // get measurement
      const measureResult: any = performance.getEntriesByName(
        measureName,
        'measure'
      );

      // log metric: console
      if (measure.log) {
        measureResult.forEach((result) => {
          console.log(measureName, result.duration);
        });
      }

      // log metric: new relic
      if (measure.newRelic && typeof window.newrelic === 'object') {
        measureResult.forEach((result) => {
          setCustomAttribute(measureName, result.duration);
        });
      }
    });
  }
};

export default setMarkMetric;

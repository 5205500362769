/**
 * getTrafficSource
 *
 * determines the source of the visitor
 *
 * @param urlString - the url to parse
 */

const getTrafficSource = (urlString: string): string => {
  const urlParams = new URLSearchParams(urlString);
  let source = (
    urlParams.has('utmCap') || urlParams.has('utm_cap')
      ? urlParams.get('utmCap') || urlParams.get('utm_cap')
      : 'organic'
  ).toLowerCase();

  if (['search', 'social'].includes(source)) {
    source = `paid ${source}`;
  }
  return source;
};

export default getTrafficSource;
